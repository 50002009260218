<template>
  <section class="dahbaord___main_wrapper d-flex flex-column vh-100">
    <div class="flex-shrink-0">
      <div class="navigation__placeholder">
        <HeaderNav
          :studentName="studentName"
          :schoolName="schoolName"
          :className="className"
          :imgPath="imgPath"
          :loading="loading"
          :nullData="nullData"
        />
      </div>
      <div class="dashboard__main__content_wrapper">
        <div class="profile__placeholder__wrapper">
          <CContainer>
            <CRow class="align-items-center">
              <CCol lg="8">
                <div
                  class="single__profile__information"
                  style="position: relative"
                >
                  <div class="d-flex" v-if="!loading">
                    <div class="profile__img_wrapper">
                      <img
                        v-if="imgPath != null"
                        :src="`${imgPath}`"
                        alt="profile-1"
                        class="img-fluid"
                      />
                      <img
                        v-else
                        src="../assets/AvatarDemo.png"
                        alt="profile-1"
                        class="img-fluid"
                      />
                    </div>
                    <div class="profile__img__content ms-2">
                      <h6 class="mb-0">{{ studentName }}</h6>
                      <p class="mb-0 mt-0">
                        {{ className }} - {{ schoolName }}
                      </p>
                    </div>
                  </div>
                  <div v-if="loading">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <label class="spin__label">Loading Data...</label>
                  </div>
                </div>
              </CCol>
              <!-- <CCol lg="4">
                <div class="single__profile__information justify-content-end">
                  <div class="profile__img_wrapper" style="position: relative">
                    <div v-if="!loading">
                      <img
                        v-if="schoolImgPath != null"
                        :src="`${schoolImgPath}`"
                        alt="profile-1"
                        class="img-fluid"
                      />
                      <img
                        v-else
                        src="../assets/AvatarDemo.png"
                        alt="profile-1"
                        class="img-fluid"
                      />
                    </div>
                    <div v-if="loading">
                      <div class="spinner-border text-warning" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol> -->
            </CRow>
          </CContainer>
        </div>
        <CContainer>
          <CRow>
            <!-- <div class="goback__student_list mt-4">
              <button @click="backtoStudentList" class="custom__btn">Go Back</button>
            </div> -->
            <CCol lg="12">
              <div class="dashbaord__inner__content__wrapper" v-if="!nullData">
                <router-view></router-view>
              </div>
              <div class="dashbaord__inner__content__wrapper" v-if="nullData">
                <div class="no__option_wrapper text-center">
                  <h4>No Options Available</h4>
                </div>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
    <div class="mt-auto">
      <div class="dashboard__footer_wraper">
        <div class="mini__footer__wrapper">
          <MiniFooter />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderNav from "../components/HeaderNav.vue";
import MiniFooter from "../components/MiniFooter.vue";
import { CRow, CCol, CContainer } from "@coreui/vue";
import axios from "axios";
export default {
  name: "dashbaordView",
  data() {
    return {
      myData: [],
      filteredItems: [],
      localStorageKey: "myItems",
      loading: true,
      schoolName: "",
      studentName: "",
      className: "",
      imgPath: "",
      schoolImgPath: "",
      nullData:true,
    };
  },
  components: {
    HeaderNav,
    MiniFooter,
  },
  methods: {
    StudentDetails() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const apiUrl = process.env.VUE_APP_API_URL;
      var model = {
        UserIdentity: token,
        StudentId: studentid,
      };
      axios
        .post(
          `${apiUrl}/MobileStudentList`,
          model
        )
        .then((response) => {
          this.myData = response.data.StudentList;
          this.filteredItems = this.myData.filter(
            (item) => item.StudentId == studentid
          );
          if (this.filteredItems.length > 0) {
            localStorage.setItem(
              this.localStorageKey,
              JSON.stringify(this.filteredItems)
            );
          }
          else{
            localStorage.removeItem("myItems");
          }
          const cachedItems = localStorage.getItem("myItems");
          if (cachedItems) {
            this.myData = JSON.parse(cachedItems);
            this.schoolName = this.myData[0].SchoolName;
            this.studentName = this.myData[0].StudentName;
            this.className = this.myData[0].ClassName;
            this.imgPath = this.myData[0].StudentPictureString;
            this.schoolImgPath = this.myData[0].SchoolLogoString;
            this.loading = false;
            this.nullData = false;
          }
          else {
            console.log("No Data");
            this.schoolName = "N/A";
            this.studentName = "N/A";
            this.className = "N/A";
            this.imgPath = null;
            this.schoolImgPath = null;
            this.loading = false;
            this.nullData = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
  mounted() {
    this.StudentDetails();
  },
};
</script>

<style>
.spin__label {
  position: relative;
  top: -7px;
  left: 10px;
}
</style>
