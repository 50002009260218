<template>
  <CContainer>
    <CRow>
      <CCol lg="12">
        <div class="text-center">
          <ul>
            <li>
              <a href="#" target="_blank"
                ><img
                  src="../assets/social-media-img/facebook.png"
                  alt="facebook"
              /></a>
            </li>
            <li>
              <a href="#" target="_blank"
                ><img
                  src="../assets/social-media-img/instagram.png"
                  alt="instagram"
              /></a>
            </li>
            <li>
              <a href="#" target="_blank"
                ><img
                  src="../assets/social-media-img/twitter.png"
                  alt="twitter"
              /></a>
            </li>
            <li>
              <a href="#" target="_blank"
                ><img
                  src="../assets/social-media-img/youtube.png"
                  alt="youtube"
              /></a>
            </li>
            <li>
              <a href="#" target="_blank"
                ><img
                  src="../assets/social-media-img/linkedin.png"
                  alt="linkedin"
              /></a>
            </li>
            <li>
              <a href="#" target="_blank"
                ><img
                  src="../assets/social-media-img/world-wide-web.png"
                  alt="web"
              /></a>
            </li>
          </ul>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { CContainer, CRow, CCol } from "@coreui/vue";
export default {
    name:"miniFooter",
    setup() {
        
    },
}
</script>
