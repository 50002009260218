<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active" aria-current="page">Results</li>
        </ol>
      </nav>
    </div>
    <CCol class="mt-4">
      <CCol lg="12">
        <form @submit.prevent="getResult">
          <CRow>
            <CCol lg="8">
              <div class="input-wrapper mb-3">
                <div class="form-floating">
                  <input
                    type="month"
                    class="form-control"
                    id="month_year"
                    v-model="monthYear"
                    placeholder="MonthYear"
                    :class="{ error: !ismonthYearValid }"
                    @blur="validatemonthYear"
                  />
                  <label for="floatingInput" class="dark___color"
                    >Select Month & Year</label
                  >
                  <div v-if="!ismonthYearValid">
                    <label class="error-label">Plear Select Month & Year</label>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol lg="4">
              <div class="form__submit__btn">
                <button class="btn btn-primary style__2__btn btn-lg w-100">
                  Show Results
                </button>
              </div>
            </CCol>
          </CRow>
        </form>
      </CCol>
    </CCol>
    <!-- <button @click="generatePDF">Generate PDF</button>
    <CRow>
      <CCol lg="12" >
        <div ref="myDiv" class="pdf__data__wrapper">
          <table class="table table-striped w-100" >
            <thead>
              <tr>
                <th>Date</th>
                <th>Subject</th>
                <th>Total Marks</th>
                <th>Obt Marks</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody  v-for="(item, index) in myData" :key="index">
              
              <tr>
                <th colspan="5">Evaluation: {{ item.EvaluationName }}</th>
              </tr>
              <tr
                v-for="(result, index) in item.EvaluationDetails"
                :key="index"
              >
                <td>{{ result.EvaluationDateString }}</td>
                <td>{{ result.Subject }}</td>
                <td>{{ result.TotalMarks }}</td>
                <td>{{ result.ObtainedMarks }}</td>
                <td>{{ result.Percentage }}</td>
              </tr>
              <tr>
                <td colspan="2">Total</td>
                <td>{{ sumTotalMarks(item.EvaluationDetails) }}</td>
                <td>
                  {{ sumObtainedMarks(item.EvaluationDetails) }}
                </td>
                <td>{{ sumPercentage(item.EvaluationDetails) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CCol>
    </CRow> -->
    <CRow class="justify-content-center mt-4" v-if="!noDataFound">
      <CCol lg="12">
        <div class="accordion__main__wrapper">
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div
              class="accordion-item mb-3"
              v-for="(item, index) in myData"
              :key="index"
            >
              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#panelsStayOpen-collapse${index}`"
                  aria-expanded="true"
                  :aria-controls="`panelsStayOpen-collapse${index}`"
                >
                  Evaluation: {{ item.EvaluationName }}
                </button>
              </h2>
              <div
                :id="`panelsStayOpen-collapse${index}`"
                class="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div class="accordion-body">
                  <div class="data__table__content__wrapper">
                    <div class="table-responsive">
                      <table class="table table-striped w-100 custom__result__table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Subject</th>
                            <th>Total Marks</th>
                            <th>obt Marks</th>
                            <th>Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(result, index) in item.EvaluationDetails"
                            :key="index"
                          >
                            <td>{{ result.EvaluationDateString }}</td>
                            <td>{{ result.Subject }}</td>
                            <td>{{ result.TotalMarks }}</td>
                            <td>{{ result.ObtainedMarks }}</td>
                            <td>{{ result.Percentage }}</td>
                          </tr>
                          <tr class="total__result">
                            <td colspan="2">Total</td>
                            <td>{{ sumTotalMarks(item.EvaluationDetails) }}</td>
                            <td>
                              {{ sumObtainedMarks(item.EvaluationDetails) }}
                            </td>
                            <td>{{ sumPercentage(item.EvaluationDetails) }} %</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
    <div v-if="noDataFound">
      <div class="no__data__information__wrapper text-center mb-5">
        <CRow class="align-items-center justify-content-center">
          <CCol lg="5">
            <img src="../../assets/empty_search.svg" width="500" alt="emptySearch" class="img-fluid">
          </CCol>
          <CCol lg="5">
            <div class="no__data_wrapper">
              <h2 class="mb-0">oops..!<br> No Data Found</h2>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { CRow, CCol } from "@coreui/vue";
// import $ from "jquery";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
export default {
  name: "ExamHistory",
  data() {
    return {
      myData: [],
      monthYear: "",
      ismonthYearValid: true,
      noDataFound:false
    };
  },
  methods: {
    validatemonthYear() {
      this.ismonthYearValid = !!this.monthYear;
    },
    async getResult() {
      this.validatemonthYear();
      if (!this.monthYear) {
        return;
      } else {
        const token = localStorage.getItem("token");
        const studentid = this.$route.params.id;
        const newmonthYear = new Date(this.monthYear);
        const year = newmonthYear.getFullYear();
        const month = newmonthYear.getMonth() + 1;
        const apiUrl = process.env.VUE_APP_API_URL;
        try {
          var model = {
            UserIdentity: token,
            StudentId: studentid,
            Month: month,
            Year: year,
          };
          const response = await axios.post(
            `${apiUrl}/MobileStudentMonthlyExamHistory`,
            model
          );
          this.myData = response.data.Evaluations;
          this.noDataFound = false;
          if(this.myData.length == 0){
            this.noDataFound = true;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    sumTotalMarks(evaluationDetails) {
      let totalmarks = 0;
      for (let i = 0; i < evaluationDetails.length; i++) {
        totalmarks += evaluationDetails[i].TotalMarks;
      }
      return totalmarks;
    },
    sumObtainedMarks(evaluationDetails) {
      let obttotal = 0;
      for (let i = 0; i < evaluationDetails.length; i++) {
        obttotal += evaluationDetails[i].ObtainedMarks;
      }
      return obttotal.toFixed(2);
    },
    sumPercentage(evaluationDetails) {
      let totalObtainedMarks = this.sumObtainedMarks(evaluationDetails);
      let totalMarks = this.sumTotalMarks(evaluationDetails);
      let percentage = (totalObtainedMarks / totalMarks) * 100;
      return percentage.toFixed(2);
    },
    // generatePDF() {
    //   const div = this.$refs.myDiv;
    //   const table = div.querySelector("table");
    //   const doc = new jsPDF();
    //   doc.autoTable({
    //     html: table,
    //   });
    //   doc.save("Result.pdf");
    // },
  },
};
</script>
