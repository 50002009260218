<template>
  <section class="payment__histroy__main__wrapper" lang="html">
    <div class="breadcrumb__main__wrapper mt-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboardOption' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Time Table
          </li>
        </ol>
      </nav>
    </div>
    <CRow class="justify-content-center mt-4 pb-4">
      <h2 class="title mb-4">Time Table</h2>

      <div class="data__table__content__wrapper mt-4 mb-5">
        <div class="table-responsive">
          <table ref="mytable" class="table table-striped w-100">
            <thead>
              <tr>
                <th>SrNo</th>
                <th>SubjectName</th>
                <th>StartDate</th>
                <th>EndDate</th>
                <th>StartTime</th>
                <th>EndTime</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="myData.length">
                <tr v-for="(item, index) in myData" :key="index">
                  <td>{{ item.SrNo }}</td>
                  <td>{{ item.SubjectName }}</td>
                  <td>{{ item.StartDate }}</td>
                  <td>{{ item.EndDate }}</td>
                  <td>{{ item.StartTime }}</td>
                  <td>{{ item.EndTime }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="6" class="text-center">No data available</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </CRow>
  </section>
</template>

<script>
import axios from "axios";
import { CRow } from "@coreui/vue";

export default {
  name: "TimeTable",
  data() {
    return {
      myData: [],
    };
  },
  mounted() {
    this.getTimeTable();
  },
  methods: {
    async getTimeTable() {
      const token = localStorage.getItem("token");
      const studentid = this.$route.params.id;
      const apiUrl = process.env.VUE_APP_API_URL;
      const mobileCode = process.env.VUE_APP_MOBILE_CODE;
      const notificationId = process.env.VUE_APP_NOTIFICATION_TYPE_ID;
      try {
        const model = {
          UserIdentity: token,
          StudentId: studentid,
          MobileCode: mobileCode,
          NotificationTypeId: notificationId,
          Month: 0
        };
        const response = await axios.post(
          `${apiUrl}/GetStudentTimeTable`,
          model
        );
        this.myData = response.data.TimeTable;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
