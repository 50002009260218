<template>
  <div class="login__content__wrapper">
    <div class="login__content_placeholder">
      
      <div class="login__form__content__wrapper">
        <form autocomplete="off" @submit.prevent="SignUpForm">
          <CRow>
            <CCol lg="6">
              <div class="input-wrapper mb-4">
                <div class="text-end">
                  <label class="info__label"
                    >Please Add Phone Like (923208108034)</label
                  >
                </div>
                <div class="form-floating">
                  <input
                    type="tel"
                    class="form-control"
                    id="phone_number"
                    placeholder="923208108034"
                    v-model="MobileNumber"
                    :class="{ error: !isMobileNumberValid }"
                    @blur="validateMobileNumber"
                    :maxlength="12"
                    @keydown="onlyNumeric"
                  />
                  <label for="floatingInput">PHONE</label>
                  <div v-if="!isMobileNumberValid">
                    <label class="error-label"
                      >Please Enter 12-digit Phone Number (923208108034)</label
                    >
                  </div>
                  <div v-if="showMobileNumberErrorMessage" class="error-label">
                    Only numeric are allowed.
                  </div>
                </div>
              </div>
            </CCol>
            <CCol lg="6">
              <div class="input-wrapper mb-4">
                <div class="text-end">
                  <label class="info__label">Please Add Code In Numarics</label>
                </div>
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="code"
                    v-model="MobileCode"
                    placeholder="code"
                    :class="{ error: !isMobileCodeValid }"
                    @blur="validateMobileCode"
                    :maxlength="4"
                    @keydown="onlyNumeric"
                  />
                  <label for="floatingInput">CODE</label>
                  <div v-if="!isMobileCodeValid">
                    <label class="error-label">Code required</label>
                  </div>
                  <div v-if="showMobileCodeErrorMessage" class="error-label">
                    Only numeric are allowed.
                  </div>
                </div>
              </div>
            </CCol>
            <CCol lg="6">
              <div class="input-wrapper mb-4">
                <div class="form-floating">
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="Password"
                    placeholder="password"
                    :class="{ error: !isPasswordValid }"
                    @blur="validatePassword"
                  />
                  <label for="floatingInput">PASSWORD</label>
                  <div v-if="!isPasswordValid">
                    <label class="error-label">Password Required</label>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol lg="6">
              <div class="input-wrapper mb-4">
                <div class="form-floating">
                  <input
                    type="password"
                    class="form-control"
                    id="confirm_password"
                    v-model="confirmPassword"
                    placeholder="Confirm Password"
                    :class="{ error: !isconfirmPasswordValid }"
                    @blur="validateconfirmPassword"
                  />
                  <label for="floatingInput">CONFIRM PASSWORD</label>
                  <div v-if="!isconfirmPasswordValid">
                    <label class="error-label">Confirm Password Required</label>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow class="align-items-center justify-content-center mt-3 mb-4">
            <CCol lg="6">
              <div class="form__submit__btn">
                <button class="btn btn-primary btn-lg w-100">Proceed</button>
              </div>
            </CCol>
          </CRow>
          <CRow class=" justify-content-center">
            <CCol lg="6" class="mt-3 mt-lg-0">
              <div class="sign__up__redirect">
                <p class="mb-0">
                  Already have an account
                  <router-link :to="{ name: 'login' }">Login</router-link>
                </p>
              </div>
            </CCol>
          </CRow>
        </form>
      </div>
      <div class="login__footer__content__wrapper mt-5">
        <div class="login__footer__content">
          <div class="powered__by">
            <div class="content__powered_by">
              <h3 class="mb-0">Powered By</h3>
            </div>
            <div class="img__powered_by">
              <img
                src="../assets/cas-white-logo.png"
                alt="cas-logo"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="tag__line">
            <p class="mb-0">Cyber Advance Solution</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { CRow, CCol } from "@coreui/vue";
export default {
  name: "SignUpForm",
  data() {
    return {
      MobileCode: "",
      isMobileCodeValid: true,
      showMobileCodeErrorMessage: false,

      MobileNumber: "",
      isMobileNumberValid: true,
      showMobileNumberErrorMessage: false,

      Password: "",
      isPasswordValid: true,

      confirmPassword: "",
      isconfirmPasswordValid: true,
    };
  },
  methods: {
    validateForm() {
      if (this.Password !== this.confirmPassword) {
        this.$swal.fire({
          icon: "error",
          title: "Password Mismatched",
          text: "Passwords do not match!",
          showCancelButton: true,
        });
        return false;
      }
      return true;
    },
    onlyNumeric(event) {
      const keyCode = event.keyCode || event.which;
      const inputField = event.target.getAttribute("id");
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 9 ||
        keyCode === 8
      ) {
        if (inputField === "phone_number") {
          this.showMobileNumberErrorMessage = false;
          return true;
        }
        if (inputField === "code") {
          this.showMobileCodeErrorMessage = false;
          return true;
        }
      }
      event.preventDefault();
      if (inputField === "phone_number") {
        this.showMobileNumberErrorMessage = true;
        return true;
      }
      if (inputField === "code") {
        this.showMobileCodeErrorMessage = true;
        return true;
      }
      return false;
    },
    validateMobileNumber() {
      this.isMobileNumberValid = !!this.MobileNumber;
    },
    validateMobileCode() {
      this.isMobileCodeValid = !!this.MobileCode;
    },
    validatePassword() {
      this.isPasswordValid = !!this.Password;
    },
    validateconfirmPassword() {
      this.isconfirmPasswordValid = !!this.confirmPassword;
    },
    SignUpForm() {
      this.validateMobileNumber();
      this.validateMobileCode();
      this.validatePassword();
      this.validateconfirmPassword();
      if (
        !this.MobileNumber ||
        !this.MobileCode ||
        !this.Password ||
        !this.confirmPassword
      ) {
        return;
      } else if (this.validateForm()) {
        
        const apiUrl = process.env.VUE_APP_API_URL;
        var model = {
          MobileCode: this.MobileCode,
          MobileNumber: this.MobileNumber,
          Password: this.Password,
          confirmPassword: this.confirmPassword,
        };
        axios
          .post(
            `${apiUrl}/LoginRegistration`,
            model
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.isValid === 1) {
              this.$swal
                .fire({
                  icon: "success",
                  title: "Account Registered",
                  text: response.data.ResponseString,
                  showCancelButton: false,
                })
                .then(() => {
                  this.$router.push({ name: "login" });
                });
            } else if (response.data.isValid === 2) {
              this.$swal.fire({
                icon: "info",
                title: "Member Already Exists",
                text: response.data.ResponseString,
                showCancelButton: true,
              });
            } else if (response.data.isValid === 0) {
              this.$swal.fire({
                icon: "error",
                title: "Validation Error",
                text: response.data.ResponseString,
                showCancelButton: true,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
};
</script>
