<template>
  <section class="d-flex flex-column vh-100">
    <div class="flex-shrink-0">
      <div class="mini__header__wrapper">
        <CContainer>
          <CRow class="align-item-center">
            <CCol xs="6">
              <div class="welcome__note">
                <h3 class="mb-0">Welcome</h3>
              </div>
            </CCol>
            <CCol xs="6">
              <div class="log__out_btn text-end">
                <button @click="logout" class="custom__btn ms-auto">Logout</button>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <div class="student__list__wrapper mt-5 mb-5">
        <div v-if="loading">
          <div class="placeholder-wrapper">
            <div class="loader-content-wrapper">
              <div class="wrapper">
                <div class="loader">
                  <div class="dot blueColor"></div>
                </div>
                <div class="loader">
                  <div class="dot yellowColor"></div>
                </div>
                <div class="loader">
                  <div class="dot blueColor"></div>
                </div>
                <div class="loader">
                  <div class="dot yellowColor"></div>
                </div>
                <div class="loader">
                  <div class="dot blueColor"></div>
                </div>
                <div class="loader">
                  <div class="dot yellowColor"></div>
                </div>
              </div>
              <div class="text">Loading<span> ....</span></div>
            </div>
          </div>
        </div>
        <CContainer>
          <CRow v-if="!loading">
            <!-- Student-list Component -- Start Here -->
            <StudentList
              v-for="student in myData"
              :key="student.StudentId"
              :StudentID="student.StudentId"
              :name="student.StudentName"
              :fatherName="student.FatherName"
              :regNumber="student.AdmissionNumber"
              :className="student.ClassName"
              :imgPath="student.StudentPictureString"
            />
            <!-- Student-list Component -- End's Here -->
          </CRow>
        </CContainer>
      </div>
    </div>
    <div class="mt-auto">
      <div class="mini__footer__wrapper">
        <MiniFooter />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import StudentList from "../components/StudentList.vue";
import MiniFooter from "../components/MiniFooter.vue";
import { CCol, CContainer, CRow } from "@coreui/vue";
export default {
  name: "StudentView",
  components: {
    StudentList,
    MiniFooter,
  },
  data() {
    return {
      myData: [],
      loading: true,
      // localStorageKey: "myItems",
    };
  },

  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("myItems");
      this.$router.push("/login");
    },
    fetchData() {
      const token = localStorage.getItem("token");
      const apiUrl = process.env.VUE_APP_API_URL;
      var model = {
        UserIdentity: token,
      };
      axios
        .post(
          `${apiUrl}/MobileStudentList`,
          model
        )
        .then((response) => {
          this.myData = response.data.StudentList;
          // localStorage.setItem(
          //   this.localStorageKey,
          //   JSON.stringify(this.myData)
          // );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          // this.loading = false;
        });
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    // const cachedItems = localStorage.getItem(this.localStorageKey);
    if (token !== null) {
      this.fetchData();
      // if (cachedItems) {
      //   console.log("Local Storage Data Load");
      //   this.myData = JSON.parse(cachedItems);
      //   this.loading = false;
      // } else {
      //   console.log("Api Data Load");
      // }
    } else {
      // this.logout();
      console.log("No Tokken Available");
    }
  },
};
</script>
